<script setup>

const {menus, options} = storeToRefs(useGlobalStore());

const footerMenu = menus.value?.find((menu) => menu?.name == "footer SASA") || [];
const message = ref("");
const form = ref(null);
const loading = ref(false);
const formData = ref({
  "your-name": "",
  "your-phone": "",
  "your-email": "",
  "your-text": "",
});
const sendForm = async () => {
  loading.value = true;
  message.value = "";

  const allinputs = form.value.querySelectorAll("input, textarea");
  const allerrors = form.value.querySelectorAll('.wpcf7-not-valid-tip');
  if (allerrors) {

    allerrors.forEach((error) => {
      error.remove();
    });
  }
  const data = new FormData();
  allinputs.forEach((input) => {
    data.append(input.name, input.value);
    input.classList.remove('wpcf7-not-valid');
  });
  const res = await wpSendForm("9120", data);
  if (res.invalid_fields.length > 0) {
    for (const field of res.invalid_fields) {

      const input = form.value.querySelector(`[name="${field.field}"]`);
      input.classList.add('wpcf7-not-valid')
      const errorDiv = document.createElement('span');
      errorDiv.classList.add('wpcf7-not-valid-tip')
      errorDiv.innerHTML = field.message;
      input.parentElement.appendChild(errorDiv);

    }
  }
  if (res.status == 'mail_sent') {
    message.value = res.message;
    form.value.reset();
  }
  // form.value.validate().then(async ({valid, errors}) => {
  //   if (valid) {
  //     let data = new FormData();
  //     for (const dataKey in formData.value) {
  //       data.append(dataKey, formData.value[dataKey].toString());
  //     }
  //
  //     const res = await wpSendForm("9120", data);
  //     if (res.invalid_fields.length > 0) {
  //       for (const field of res.invalid_fields) {
  //         form.value.items.indexOf();
  //         const find = form.value.items.find((f) => f.id == field.field);
  //         //find.isValid = false;
  //         find.errorMessages.push(field.message);
  //       }
  //     }
  //     if (res.status == 'mail_sent') {
  //       message.value = res.message;
  //       form.value.reset();
  //     }
  //   }
  // });
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};
</script>
<template>
  <footer>
    <div class="fixed-chat mobile_only" href="javascript:void(0)" target="_blank">

      <nuxt-img class="fixed-chat__main" src="images/svg/contact_us.svg"
                onclick="$('#contactMobile').modal('show')"/>
    </div>
    <div class="footer-inner">
      <div class="container pb-5">
        <div class="row justify-md-between">
          <div class="col">
            <div class="row justify-md-between">
              <div class="col-md-2">
                <div class="ft_menu-wrap">
                  <div class="ft_menu_title" v-slide-toggle>
                    אזור אישי
                    <span class="mobile_only ft_menu_title-arrow">
                      <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 16 29"
                          style="visibility: visible"
                          xml:space="preserve"
                          class="svg-clean"
                      >
                        <g>
                          <path
                              id="Chevron_Right"
                              d="M0.4,15.5l13.1,13.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-12-12l12-12c0.6-0.6,0.6-1.5,0-2.1
            c-0.6-0.6-1.5-0.6-2.1,0L0.4,13.5C-0.1,14-0.1,15,0.4,15.5z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>

                  <ul class="ft_menu">
                    <li class="menu-item">
                      <nuxt-link to="/my-account">החשבון שלי</nuxt-link>
                    </li>
                    <li class="menu-item">
                      <nuxt-link to="/my-account/orders">היסטוריית הזמנות</nuxt-link>
                    </li>
                  </ul>
                </div>
                <div class="ft_socials-wrap no_mobile">
                  <div class="ft_menu_title">אנחנו גם פה</div>
                  <div class="d-flex justify-content-md-between">
                    <nuxt-link target="_blank" class="ft_social" href="https://www.facebook.com/sasa.shtihim">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 10.7 23.2"
                          style="visibility: visible"
                          class="svg-clean"
                      >
                        <defs></defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <path
                                class=""
                                d="M2.4,23.2H7.1V11.5h3.3l.3-3.9H7.1V5.4c0-.9.2-1.3,1.1-1.3h2.5V0H7.5c-3.5,0-5,1.5-5,4.5V7.6H0v4H2.4Z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </nuxt-link>
                    <nuxt-link target="_blank" class="ft_social" href="https://www.instagram.com/sasa_shtihim/">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 21.2 21.1"
                          style="visibility: visible"
                          class="svg-clean"
                      >
                        <defs></defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <g>
                              <path
                                  class=""
                                  d="M10.5,1.9a38.08,38.08,0,0,1,4.2.1,4.3,4.3,0,0,1,2,.4,3.17,3.17,0,0,1,1.2.8,3.17,3.17,0,0,1,.8,1.2,9.49,9.49,0,0,1,.4,2,38.08,38.08,0,0,1,.1,4.2,38.08,38.08,0,0,1-.1,4.2,4.3,4.3,0,0,1-.4,2,3.68,3.68,0,0,1-2,2,9.49,9.49,0,0,1-2,.4,38.08,38.08,0,0,1-4.2.1,38.08,38.08,0,0,1-4.2-.1,4.3,4.3,0,0,1-2-.4A3.17,3.17,0,0,1,3.1,18a3.17,3.17,0,0,1-.8-1.2,9.49,9.49,0,0,1-.4-2,38.08,38.08,0,0,1-.1-4.2,38.08,38.08,0,0,1,.1-4.2,4.3,4.3,0,0,1,.4-2,3.17,3.17,0,0,1,.8-1.2,3.17,3.17,0,0,1,1.2-.8,9.49,9.49,0,0,1,2-.4,38.08,38.08,0,0,1,4.2-.1m0-1.9A39.24,39.24,0,0,0,6.2.1,8.56,8.56,0,0,0,3.6.6,5.54,5.54,0,0,0,1.7,1.8,5.54,5.54,0,0,0,.5,3.7,8,8,0,0,0,0,6.3v4.2a39.24,39.24,0,0,0,.1,4.3,8.56,8.56,0,0,0,.5,2.6,5.54,5.54,0,0,0,1.2,1.9,5.54,5.54,0,0,0,1.9,1.2,8,8,0,0,0,2.6.5,40.37,40.37,0,0,0,4.3.1,39.24,39.24,0,0,0,4.3-.1,8.56,8.56,0,0,0,2.6-.5,5.1,5.1,0,0,0,3.1-3.1,8,8,0,0,0,.5-2.6,40.37,40.37,0,0,0,.1-4.3,39.24,39.24,0,0,0-.1-4.3,8.56,8.56,0,0,0-.5-2.6,5.54,5.54,0,0,0-1.2-1.9A5.54,5.54,0,0,0,17.5.5,8,8,0,0,0,14.9,0H10.5"
                              ></path>
                              <path
                                  class=""
                                  d="M10.5,5.1a5.4,5.4,0,1,0,5.4,5.4,5.38,5.38,0,0,0-5.4-5.4m0,8.9A3.5,3.5,0,1,1,14,10.5,3.54,3.54,0,0,1,10.5,14"
                              ></path>
                              <path class="" d="M17.4,4.9a1.3,1.3,0,1,1-1.3-1.3,1.32,1.32,0,0,1,1.3,1.3"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </nuxt-link>
                    <nuxt-link target="_blank" class="ft_social" href="https://youtube.com/@sasa_shtihim">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 30.61 21.4"
                          style="visibility: visible"
                          class="svg-clean"
                      >
                        <defs></defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <path
                                class=""
                                d="M30.1,3.4A4,4,0,0,0,27.3.6c-2.4-.6-12-.6-12-.6S5.7,0,3.3.6A3.87,3.87,0,0,0,.6,3.3,42.79,42.79,0,0,0,0,10.7a42.79,42.79,0,0,0,.6,7.4,3.87,3.87,0,0,0,2.7,2.7c2.4.6,12,.6,12,.6s9.6,0,12-.6A3.87,3.87,0,0,0,30,18.1a42.79,42.79,0,0,0,.6-7.4A38.6,38.6,0,0,0,30.1,3.4Zm-17.9,12V6.2l8,4.6Z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </nuxt-link>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div v-for="menu in footerMenu?.menuItems?.nodes" class="ft_menu-wrap col-md col-12">
                    <div class="ft_menu_title" v-slide-toggle>
                      {{ menu.label }}
                      <span class="mobile_only ft_menu_title-arrow">
                        <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 16 29"
                            style="visibility: visible"
                            xml:space="preserve"
                            class="svg-clean"
                        >
                          <g>
                            <path
                                id="Chevron_Right"
                                d="M0.4,15.5l13.1,13.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-12-12l12-12c0.6-0.6,0.6-1.5,0-2.1
            c-0.6-0.6-1.5-0.6-2.1,0L0.4,13.5C-0.1,14-0.1,15,0.4,15.5z"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <ul class="ft_menu">
                      <li class="menu-item" v-for="item in menu?.childItems?.nodes" :key="item.uri">
                        <nuxt-link :to="item?.uri">{{ item?.label }}</nuxt-link>
                      </li>
                    </ul>
                  </div>
                  <div class="ft_contact col">
                    <div class="ft_menu_title" v-slide-toggle>
                      יצירת קשר
                      <span class="mobile_only ft_menu_title-arrow">
                        <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 16 29"
                            style="visibility: visible"
                            xml:space="preserve"
                            class="svg-clean"
                        >
                          <g>
                            <path
                                id="Chevron_Right"
                                d="M0.4,15.5l13.1,13.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-12-12l12-12c0.6-0.6,0.6-1.5,0-2.1
            c-0.6-0.6-1.5-0.6-2.1,0L0.4,13.5C-0.1,14-0.1,15,0.4,15.5z"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div class="ft_menu">
                      <div class="contact_link d-flex align-items-center">
                        <span class="contact_link__icon ml-2"
                        ><!--?xml version="1.0" encoding="utf-8"?-->
                          <!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 18.6 23.3"
                              style="visibility: visible"
                              xml:space="preserve"
                              class="svg-clean"
                          >
                            <path
                                class=""
                                d="M9.3,0.7c2.3,0,4.5,1,6,2.5c1.5,1.5,2.5,3.7,2.5,6c0,1.4-0.3,2.7-0.9,3.8c-1.1,2.2-5.5,7.3-7.6,9.4
        c-2.1-2.1-6.5-7.2-7.6-9.4c-0.6-1.1-0.9-2.4-0.9-3.8C0.8,4.5,4.6,0.7,9.3,0.7L9.3,0.7z"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <polygon
                                class=""
                                points="9.3,4.4 10.7,7.2 13.8,7.7 11.7,10 12.1,13.1 9.3,11.7 6.4,13.1 6.9,10 4.7,7.7 7.8,7.2 9.3,4.4 "
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></polygon>
                          </svg>
                        </span>
                        <span class="contact_link__text">
                          כתובת: <br/>
                          מרכז שרות, קיבוץ מענית
                        </span>
                      </div>
                      <a
                          class="contact_link d-flex align-items-center"
                          target="_blank"
                          href=" http://maps.google.com/?q=שטיחים לרכב | שטיחים לרכב לפי מידה מקוריים מ-SASA"
                      >
                        <!--								<a class="contact_link d-flex align-items-center" href="https://waze.com/ul?q=-->
                        <!--">-->
                        <span class="contact_link__icon ml-2"
                        ><!--?xml version="1.0" encoding="utf-8"?-->
                          <!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                          <svg
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 25.2 23.5"
                              style="visibility: visible"
                              xml:space="preserve"
                              class="svg-clean"
                          >
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M14.3,0.1c-6,0-10.9,4.6-10.9,10.3c0.1,0.6,0.1,2.2-0.6,3.1c-0.4,0.5-1,0.7-1.7,0.7c-0.4,0-0.8,0.3-0.8,0.8
                c0,2.4,2,4,5.8,4.8c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.3,0.8-0.6c0.1-0.4-0.2-0.8-0.6-0.9c-2.6-0.5-4-1.4-4.5-2.6
                c0.8-0.2,1.5-0.5,2-1.1c1.4-1.6,1-4.2,1-4.2c0-4.7,4.2-8.6,9.4-8.6s9.4,3.9,9.4,8.6c0,3.2-2,6.2-5.1,7.7c-0.4,0.2-0.6,0.7-0.4,1
                c0.2,0.4,0.7,0.5,1,0.4c3.7-1.8,6-5.2,6-9.1C25.2,4.7,20.3,0.1,14.3,0.1z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M16,19.5c0-0.4-0.5-0.7-0.9-0.7c-0.3,0-0.6,0-0.9,0c-1.6,0-2.9,0-4-0.1c-0.4,0-0.8,0.3-0.8,0.7
                c0,0.4,0.3,0.8,0.7,0.8c1.2,0.1,2.5,0.1,4.2,0.1c0.3,0,0.7,0,1-0.1C15.7,20.3,16,19.9,16,19.5z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M17.4,17.3c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1S19.1,17.3,17.4,17.3z M17.4,22
                c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6S18.3,22,17.4,22z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M8,17.3c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1S9.7,17.3,8,17.3z M8,22
                c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6S8.9,22,8,22z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M11.2,7.1L11.2,7.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8
                C12,7.5,11.6,7.1,11.2,7.1z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M17.4,7.1L17.4,7.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8
                C18.2,7.5,17.9,7.1,17.4,7.1z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                    class=""
                                    d="M18.3,11.1c-0.4-0.1-0.8,0.2-0.9,0.6c-0.3,1.5-1.6,2.5-3.1,2.5s-2.8-1-3.1-2.5c-0.1-0.4-0.5-0.7-0.9-0.6
                c-0.4,0.1-0.7,0.5-0.6,0.9c0.5,2.2,2.4,3.7,4.7,3.7c2.3,0,4.2-1.5,4.7-3.7C19,11.6,18.8,11.2,18.3,11.1z"
                                    style="fill: rgb(255, 255, 255)"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <span class="contact_link__text"> נווט </span>
                      </a>
                      <a
                          class="contact_link d-flex align-items-center no_mobile"
                          href="javascript:void(0)"
                          @click.prevent="$activeModalsBus.event('openPopupPhone')"
                      >
                        <span class="contact_link__icon ml-2"
                        ><!--?xml version="1.0" encoding="utf-8"?-->
                          <!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 26.8 25.6"
                              style="visibility: visible"
                              xml:space="preserve"
                              class="svg-clean"
                          >
                            <path
                                class=""
                                d="M7.3,8.9c-1.2,1.2-0.9,3.9,0.9,6.3c1.8,2.3,4.3,3.4,5.8,2.5"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M18.1,23c-0.1,0.1-0.3,0.3-1.4,1.1c-2.1,1.5-6.9-1.3-11.2-6.9c-4.2-5.5-5.7-11-3.7-12.6c1-0.9,1.3-1.1,1.4-1.1"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M18.5,22.2l-3.9-5.1c-0.2-0.3-0.2-0.7,0.1-0.9l1.8-1.4c0.3-0.2,0.7-0.2,0.9,0.1l3.9,5.1
        c0.2,0.3,0.2,0.7-0.1,0.9l-1.8,1.4C19.2,22.6,18.8,22.5,18.5,22.2L18.5,22.2z"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M8,8.5L4.1,3.4C3.9,3.1,3.9,2.6,4.2,2.4L6,1c0.3-0.2,0.7-0.2,0.9,0.1l3.9,5.1C11.1,6.6,11,7,10.7,7.2L8.9,8.6
        C8.7,8.8,8.2,8.8,8,8.5L8,8.5z"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <polyline
                                class=""
                                points="24.2,9.7 24.2,0.9 19.9,7.1 26,7 "
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></polyline>
                            <polyline
                                class=""
                                points="18.9,9.7 13.4,9.7 16.7,6.4 "
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></polyline>
                            <path
                                class=""
                                d="M16.7,6.4c2.3-2.1,1.6-4.2,0.2-4.9c-2.2-1.2-3.8,1.2-3.6,2.7"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                          </svg>
                        </span>
                        <span class="contact_link__text">
                          שירות לקוחות: <br/>
                          0555522553
                        </span>
                      </a>
                      <a
                          class="contact_link d-flex align-items-center mobile_only"
                          href="tel:0555522553"
                      >
                        <span class="contact_link__icon ml-2"
                        ><!--?xml version="1.0" encoding="utf-8"?-->
                          <!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 26.8 25.6"
                              style="visibility: visible"
                              xml:space="preserve"
                              class="svg-clean"
                          >
                            <path
                                class=""
                                d="M7.3,8.9c-1.2,1.2-0.9,3.9,0.9,6.3c1.8,2.3,4.3,3.4,5.8,2.5"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M18.1,23c-0.1,0.1-0.3,0.3-1.4,1.1c-2.1,1.5-6.9-1.3-11.2-6.9c-4.2-5.5-5.7-11-3.7-12.6c1-0.9,1.3-1.1,1.4-1.1"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M18.5,22.2l-3.9-5.1c-0.2-0.3-0.2-0.7,0.1-0.9l1.8-1.4c0.3-0.2,0.7-0.2,0.9,0.1l3.9,5.1
        c0.2,0.3,0.2,0.7-0.1,0.9l-1.8,1.4C19.2,22.6,18.8,22.5,18.5,22.2L18.5,22.2z"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <path
                                class=""
                                d="M8,8.5L4.1,3.4C3.9,3.1,3.9,2.6,4.2,2.4L6,1c0.3-0.2,0.7-0.2,0.9,0.1l3.9,5.1C11.1,6.6,11,7,10.7,7.2L8.9,8.6
        C8.7,8.8,8.2,8.8,8,8.5L8,8.5z"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                            <polyline
                                class=""
                                points="24.2,9.7 24.2,0.9 19.9,7.1 26,7 "
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></polyline>
                            <polyline
                                class=""
                                points="18.9,9.7 13.4,9.7 16.7,6.4 "
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></polyline>
                            <path
                                class=""
                                d="M16.7,6.4c2.3-2.1,1.6-4.2,0.2-4.9c-2.2-1.2-3.8,1.2-3.6,2.7"
                                style="
                                fill: none;
                                stroke: rgb(255, 255, 255);
                                stroke-width: 1.44;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 22.9256;
                              "
                            ></path>
                          </svg>
                        </span>
                        <span class="contact_link__text">
                          שירות לקוחות: <br/>
                          0555522553
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center align-items-start no_mobile">
                  <img
                      style="max-width: 250px"
                      src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/secure payment.svg"
                      alt="אייקון כרטיסי אשראי"
                  />
                </div>
              </div>
              <div class="ft_socials-wrap mobile_only d-flex justify-content-between col-12">
                <div class="ft_menu_title">אנחנו גם פה</div>
                <div class="d-flex justify-content-md-between col">
                  <a class="ft_social mr-4" href="https://www.facebook.com/sasa.shtihim">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10.7 23.2"
                        style="visibility: visible"
                        class="svg-clean"
                    >
                      <defs></defs>
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <path
                              class=""
                              d="M2.4,23.2H7.1V11.5h3.3l.3-3.9H7.1V5.4c0-.9.2-1.3,1.1-1.3h2.5V0H7.5c-3.5,0-5,1.5-5,4.5V7.6H0v4H2.4Z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a class="ft_social mr-4" href="https://www.instagram.com/sasa_shtihim/">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.2 21.1"
                        style="visibility: visible"
                        class="svg-clean"
                    >
                      <defs></defs>
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <g>
                            <path
                                class=""
                                d="M10.5,1.9a38.08,38.08,0,0,1,4.2.1,4.3,4.3,0,0,1,2,.4,3.17,3.17,0,0,1,1.2.8,3.17,3.17,0,0,1,.8,1.2,9.49,9.49,0,0,1,.4,2,38.08,38.08,0,0,1,.1,4.2,38.08,38.08,0,0,1-.1,4.2,4.3,4.3,0,0,1-.4,2,3.68,3.68,0,0,1-2,2,9.49,9.49,0,0,1-2,.4,38.08,38.08,0,0,1-4.2.1,38.08,38.08,0,0,1-4.2-.1,4.3,4.3,0,0,1-2-.4A3.17,3.17,0,0,1,3.1,18a3.17,3.17,0,0,1-.8-1.2,9.49,9.49,0,0,1-.4-2,38.08,38.08,0,0,1-.1-4.2,38.08,38.08,0,0,1,.1-4.2,4.3,4.3,0,0,1,.4-2,3.17,3.17,0,0,1,.8-1.2,3.17,3.17,0,0,1,1.2-.8,9.49,9.49,0,0,1,2-.4,38.08,38.08,0,0,1,4.2-.1m0-1.9A39.24,39.24,0,0,0,6.2.1,8.56,8.56,0,0,0,3.6.6,5.54,5.54,0,0,0,1.7,1.8,5.54,5.54,0,0,0,.5,3.7,8,8,0,0,0,0,6.3v4.2a39.24,39.24,0,0,0,.1,4.3,8.56,8.56,0,0,0,.5,2.6,5.54,5.54,0,0,0,1.2,1.9,5.54,5.54,0,0,0,1.9,1.2,8,8,0,0,0,2.6.5,40.37,40.37,0,0,0,4.3.1,39.24,39.24,0,0,0,4.3-.1,8.56,8.56,0,0,0,2.6-.5,5.1,5.1,0,0,0,3.1-3.1,8,8,0,0,0,.5-2.6,40.37,40.37,0,0,0,.1-4.3,39.24,39.24,0,0,0-.1-4.3,8.56,8.56,0,0,0-.5-2.6,5.54,5.54,0,0,0-1.2-1.9A5.54,5.54,0,0,0,17.5.5,8,8,0,0,0,14.9,0H10.5"
                            ></path>
                            <path
                                class=""
                                d="M10.5,5.1a5.4,5.4,0,1,0,5.4,5.4,5.38,5.38,0,0,0-5.4-5.4m0,8.9A3.5,3.5,0,1,1,14,10.5,3.54,3.54,0,0,1,10.5,14"
                            ></path>
                            <path class="" d="M17.4,4.9a1.3,1.3,0,1,1-1.3-1.3,1.32,1.32,0,0,1,1.3,1.3"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a class="ft_social mr-4" href="https://youtube.com/@sasa_shtihim">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.61 21.4"
                        style="visibility: visible"
                        class="svg-clean"
                    >
                      <defs></defs>
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <path
                              class=""
                              d="M30.1,3.4A4,4,0,0,0,27.3.6c-2.4-.6-12-.6-12-.6S5.7,0,3.3.6A3.87,3.87,0,0,0,.6,3.3,42.79,42.79,0,0,0,0,10.7a42.79,42.79,0,0,0,.6,7.4,3.87,3.87,0,0,0,2.7,2.7c2.4.6,12,.6,12,.6s9.6,0,12-.6A3.87,3.87,0,0,0,30,18.1a42.79,42.79,0,0,0,.6-7.4A38.6,38.6,0,0,0,30.1,3.4Zm-17.9,12V6.2l8,4.6Z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="form-news-wrap">
            <div class="ft_contanct_form">
              <div class="ft_menu_title">שאלה? הערה? הצעה? אנחנו פה בשבילך!</div>
              <div class="form">
                <div class="wpcf7 js" id="wpcf7-f9120-o1" lang="en-US" dir="ltr">
                  <div class="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                    <ul></ul>
                  </div>
                  <form
                      action="/#wpcf7-f9120-o1"
                      method="post"
                      ref="form"
                      @submit.prevent="sendForm"
                      class="wpcf7-form init"
                      aria-label="Contact form"
                      novalidate="novalidate"
                      data-status="init"
                  >
                    <div class="form_input">
                      <p>
                        <span class="wpcf7-form-control-wrap" data-name="your-name"
                        ><input
                            size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="שם..."
                            value=""
                            type="text"
                            name="your-name"
                        /></span>
                      </p>
                    </div>
                    <div class="form_input">
                      <p>
                        <span class="wpcf7-form-control-wrap" data-name="your-tel"
                        ><input
                            size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="טלפון..."
                            value=""
                            type="tel"
                            name="your-tel"
                        /></span>
                      </p>
                    </div>
                    <div class="form_input">
                      <p>
                        <span class="wpcf7-form-control-wrap" data-name="your-email"
                        ><input
                            size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                            aria-invalid="false"
                            placeholder="אימייל...(לא חובה)"
                            value=""
                            type="email"
                            name="your-email"
                        /></span>
                      </p>
                    </div>
                    <div class="form_row">
                      <div class="form_input form_check">
                        <p>
                          <label for="" class="position-relative"
                          ><br/>
                            <input type="checkbox" name="check" checked="" value="1" id=""/>ברצוני לקבל עדכונים והטבות
                            על מבצעים</label
                          >
                        </p>
                      </div>
                      <div class="bt_red  form_submit form_newsletter">
                        <p>
                          <v-btn type="submit" size="small" class="font-bold" min-height="30" text="חזרו אליי" :loading="loading"></v-btn>
                        </p>
                      </div>
                    </div>
                  </form>
                  <div class="border text-green px-2 " v-if="message">{{ message }}</div>
                </div>
              </div>
              <div class="text-white my-3">
                אנו מתמחים בייצור שטיחי יוקרה לכל סוגי הרכבים בהתאמה אישית. את שטיחי SASA ניתן להזמין דרך האתר ולקבל עם
                משלוח אישי עד הבית.
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="sp sp--dark"></span>
      <div class="copyright-text text-white py-3">
        <div class="container">
          <div class="text-center">כל הזכויות שמורות ל- סאסא טראסט בע''מ 2018 ©</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss">
.form_newsletter {
    .v-btn__content{

    font-size: 16px;
    line-height: 0;
    }
  .v-btn__loader > .v-progress-circular {
    width: 1em;
    height: 1em;
  }
}
</style>